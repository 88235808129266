import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Container, Row } from 'react-bootstrap';
import styles from '../assets/css/mooverr.module.css';
import MooverrColors from "../utils/colors";
import ProductList from "../components/SellerShop/ProductList";
import Endpoints from "../config/urls";

const SellerShop = () => {

  const { shopOwnerId } = useParams();
  const [sellerData, setSellerData] = useState([]);
  const [productList, setProductsList] = useState([]);
  const [shopBadge, setShopBadge] = useState(null);
  const baseAssetsUrl = "../assets/images/";

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(Endpoints.get_my_shop, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(
            {
              username: shopOwnerId,
              currency: "NGN"
            }
          ),
        });
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        const data = await response.json();
        const products = data["data"][0]["gig_products"]["data"];
        let shopBadge = baseAssetsUrl + 'tier' + data["data"][0].badges.toString() + '.png'

        setSellerData(data["data"][0]);
        setShopBadge(shopBadge);
        setProductsList(products);

      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };

    fetchData();
  }, [shopOwnerId]);

  return (
    <div className="">
      {/* background-color: #0C2344; height:70px */}
      <Row style={{ backgroundColor: MooverrColors.secondaryColor }}>
        <div className="col pt-2 mx-5 p-2">
          <img className={styles.brandLogo} src={require('../assets/images/logo.png')} alt="" />
          <span className={styles.brandName}>Mooverr</span>
        </div>
      </Row>

      <Container>
        <Row className="mt-5">
          <h5 className="pt-2"><b>Seller Shop</b></h5>
          <div className={`col-md-12 ${styles.shopBack}`}> </div>
        </Row>


        <Row className="shadow-sm p-5" style={{ height: 'auto' }}>

          <div className="col-md-2">
            <img src={sellerData.shop_image} alt="" className={styles.shopImage} />
          </div>
          <div className={`${styles.shopDetails} col-md-10`} style={{ marginTop: "-20px" }}>
            <div className="row">
              <div className="col-md-9">
                <h4 className="ml-4"><b>{sellerData.shop_name}</b>
                  {shopBadge != null ?
                    <img className={`${styles.badgeImage}`} src={require('../assets/images/logo.png')} alt="" />
                    : null}
                </h4>

                <h6 className={`${styles.shopDesc} ml-4 `}>{sellerData.shop_description}</h6>
                <br />
                <h6 className={`${styles.shopDesc} ml-4 `}>
                  <i className="fa fa-map-marker"></i>&nbsp; {sellerData.shop_address}
                </h6>
              </div>
              <div className="col-md-3">
                <span className={` ${styles.shopLocation} d-flex justify-content-end`}>
                  <i className={`${styles.ratingStar} fa fa-star`}></i> {sellerData.ratings}
                </span>
                <br /><br />
                <span className={` ${styles.shopLocation} d-flex justify-content-end`}>
                  <b className={sellerData.shop_availability === 'Open' ? `${styles.shopOpen}` : `${styles.shopClosed}`}>
                    {sellerData.shop_availability}
                  </b>
                </span>
              </div>
            </div>

          </div>

          <hr className="mt-2" />

          <ProductList sellerData={productList} />

        </Row>

      </Container>


      <Row className="mt-5">
        <div className="col-lg-12 d-flex justify-content-center">
          <div className="footer">
            <p>&copy; 2023. All Rights Reserved. Mooverr Limited, UK.</p>
          </div>
        </div>
      </Row>
    </div>
  );
};

export default SellerShop;