import React, { useState, useEffect } from "react";
import styles from '../assets/css/mooverr.module.css';

const KycSuccess = () => {

  return (
    <div className={styles.pageBack}>
      <div className="container">
        <div className="row">
          <div className="col mt-4">
            <img className={styles.brandLogo} src={require('../assets/images/logo.png')} alt="" class="kyc-image" />
            <span className={styles.brandName}>Mooverr</span>
          </div>

        </div>

        <div className="row mt-5 my-5">
          <div className="col-md-7">
            <h1 className={`${styles.contentHeader}`}>KYC <br />Submitted<br />Successfully</h1>
            <p className={`mt-4 ${styles.contentDesc}`}>
              Stripe Team is now in the process of reviewing your submission, and will send an update to your email within the next 48 hours. Thank You.
            </p>
            <button onclick="window.open('https://mooverr.co.uk','_self')" className={`mt-2 ${styles.actionBtn}`}>Continue</button>
          </div>

          <div className="col-md-5 d-flex mt-5 justify-content-center">
            <img src={require('../assets/images/kyc_image.png')} alt="" className={styles.kycImage} />
          </div>

        </div>
      </div>
    </div>
  );
};

export default KycSuccess;