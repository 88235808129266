import logo from './logo.svg';
import { Link, Route, Routes } from 'react-router-dom';
import KycSuccess from './pages/KycSuccess';
import SellerShop from './pages/SellerShop';

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/kyc-success" element={<KycSuccess />} />
        <Route path="/seller/:shopOwnerId" element={<SellerShop />} />
      </Routes>
    </div>
  );
}

export default App;
