import React from 'react';
import styles from '../../assets/css/mooverr.module.css';

const ProductList = ({ sellerData }) => {

  return (
    <div className="row">
      <h5><b>Products</b></h5>

      {sellerData.map((data) => (
        <div key={data["product_id"]} className="col-md-2 col-lg-2 col-sm-2 shadow-sm rounded p-0 m-3">
          <img src={data['product_image']} alt="" className={styles.prodImg} />
          <div className="p-3">
            <h3 className={`${styles.prodTitle} px-6 mt-2`}>
              {data['product_name']}
            </h3>
            <p className={` ${styles.prodDesc} px-6`}>
              {data['product_description']}

            </p>
            <h3 className={` ${styles.prodPrice} px-6 `}>
              {data['currency'] + data['product_price']}

            </h3>
            <br />
          </div>

        </div>
      ))}
    </div>
  );
};

export default ProductList;
