const MooverrColors = {
    primaryColor: '#FF4D00',
    secondaryColor: '#0C2344',

    backgroundColor: '#F4F3F8',

    //Black
    black: '#000000',
    blueNavy: '#0D4391',
    semiBlack: '#292D32',
    textBlack: '#0C2344',
    //White
    white: '#FFFFFF',
    //Grey
    grey: '#8F92A1',
    greyLight: '#F1F1F1',
    borderColor: '#A3B3DE',
    borderBackgroundColor: '#F6F7FC',
    cartGrey: '#A5BDDF',

    //Grey
    lowGrey: '#F4F6FA',

    //Red
    red: '#B8142D',

    //Green
    green: '#26D28A',

    //Yellow
    yellow: '#FDE428',

    //Lemon
    lemon: '#E7FFE4',

    //deepGreen
    deepGreen: '#2F8D26',

    //themeGreen
    themeGreen: '#7DBD64',
    textGreen: '#07B46B',

    //purple
    purple: '#9B51E0',

    blue: '#6772E5',

    lighterYellow: '#fae4db',

    //orange accent
    orangeAccent: '#F3B53F',

}

export default MooverrColors;